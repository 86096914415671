import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { downloadExcel } from "@/lib/downloadExcel";
import { Button } from "@pulso/components/lib/Button";
import { FileInput } from "@pulso/components/lib/FileInput";
import { Workbook } from "exceljs";
import { ArrowRight } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type ImportExportPricesProps = {
  product: { name: string };
  variants: { label: string; hash: string }[];
  seasons: { id: string; name: string }[];
  prices: Record<string, Record<string, null | undefined | number>>;
  periods: { id: string; name: string }[];
  onChange: (prices: Record<string, Record<string, null | undefined | number>>) => void;
};

export function ImportExportPrices(props: ImportExportPricesProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button type="button" variant="outline">
          {t("products_pricing_import_export_button", "Import / Export prices")}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("products_pricing_import_export_title", "Import/Export prices")}</DialogTitle>
          <DialogDescription>
            {t("products_pricing_import_export_description", "Import and export prices for this product.")}
          </DialogDescription>
        </DialogHeader>
        {open && (
          <div className="mt-6">
            <div className="flex gap-3">
              <div className="flex-1 p-6 rounded-lg border flex flex-col gap-6">
                <h2 className="font-bold">{t("products_pricing_import_export_step1", "Step 1")}</h2>
                <p className="text-sm">
                  {t("products_pricing_import_export_step1_description", "Export all prices into an Excel file.")}
                </p>
                <Button type="button" variant="secondary" onClick={() => downloadPricesExcel()}>
                  {t("products_pricing_import_export_export_button", "Download")}
                </Button>
              </div>
              <div className="flex items-center">
                <ArrowRight />
              </div>
              <div className="flex-1 p-6 rounded-lg border flex flex-col gap-6">
                <h2 className="font-bold">{t("products_pricing_import_export_step2", "Step 2")}</h2>
                <p className="text-sm">
                  {t("products_pricing_import_export_step2_description", "Import the updated prices.")}
                </p>
                <FileInput className="w-full" value={null} onChange={parseFile} />
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );

  async function parseFile(file: File) {
    const wb = new Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer = reader.result;
      if (buffer instanceof ArrayBuffer) {
        wb.xlsx.load(buffer).then((workbook) => {
          const sheet = workbook.getWorksheet(1);
          if (!sheet) {
            return;
          }
          const prices: Record<string, Record<string, number | null>> = {};

          for (let row = 4; row <= sheet.rowCount; row++) {
            const periodName = sheet.getRow(row).getCell(1).value as string;
            const periodId = props.periods.find((p) => p.name === periodName)?.id;
            if (!periodId) {
              continue;
            }

            for (let col = 2; col <= sheet.columnCount; col++) {
              const colKey = sheet.getCell(1, col).value || "";
              if (typeof colKey !== "string") {
                continue;
              }

              const [variantHash, seasonId] = colKey.split("_");
              if (!variantHash || !seasonId) {
                continue;
              }

              let price = sheet.getCell(row, col).value;

              if (typeof price === "string") {
                price = parseFloat(price);
              } else if (typeof price !== "number") {
                price = null;
              }

              if (!prices[variantHash]) {
                prices[variantHash] = {};
              }
              prices[variantHash][`${periodId}_${seasonId}`] = price;
            }
          }

          props.onChange(prices);
          setOpen(false);
        });
      }
    };
  }

  function downloadPricesExcel() {
    const workbook = new Workbook();
    workbook.creator = "Pulso";
    workbook.lastModifiedBy = "Pulso";
    workbook.created = new Date();
    workbook.modified = new Date();
    const sheet = workbook.addWorksheet(props.product.name);
    const columns: typeof sheet.columns = [{ width: 20 }];
    sheet.views = [
      {
        state: "frozen",
        xSplit: 1,
        ySplit: 1,
      },
    ];
    const rows: (number | string | null)[][] = [];

    const seasonsWithDefault = [{ id: "default", name: "Default" }, ...props.seasons];

    for (const variant of props.variants) {
      for (const season of seasonsWithDefault) {
        columns.push({
          header: [`${variant.hash}_${season.id}`, variant.label, season.name],
        });
      }
    }
    for (const period of props.periods) {
      const row: (number | string | null)[] = [];
      row.push(period.name);

      for (const variant of props.variants) {
        for (const season of seasonsWithDefault) {
          row.push(props.prices?.[variant.hash]?.[`${period.id}_${season.id}`] ?? null);
        }
      }
      rows.push(row);
    }
    sheet.columns = columns;
    sheet.addRows(rows);
    sheet.getCell(2, 1).value = props.product.name;
    sheet.getCell(3, 1).value = "Season";
    sheet.getRow(1).hidden = true;
    sheet.getRow(3).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFF0F0F0" } };
    sheet.getRow(2).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFF0F0F0" } };

    downloadExcel("pricing.xlsx", workbook);
  }
}
