"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorLabelResolver = errorLabelResolver;
exports.isConflictingStateError = isConflictingStateError;
function errorLabelResolver(t) {
    const conflictingStateErrorTranslations = {
        BOOKING_DELIVERY_IN_THE_FUTURE: () => t("booking_delivery_error_inFuture", "Only bookings scheduled for today can be delivered. Please reschedule this booking before delivering."),
        BOOKING_DELIVERY_IN_THE_PAST: () => t("booking_delivery_error_inPast", "Only bookings scheduled for today can be delivered. Please reschedule this booking before delivering."),
        BOOKING_DELIVERY_WITH_UNASSIGNED_ITEMS: () => t("booking_delivery_error_unassignedItems", "All items need to be assigned in order to deliver a booking."),
        BOOKING_DELIVERY_WITH_DOUBLE_BOOKED_ITEMS: () => t("booking_delivery_error_doubleBookedStockItems", "This booking has stock items which are booked for the same period in another booking. Resolve the double booking issue to be able to deliver this booking."),
        BOOKING_DELIVERY_WITH_MISSING_SIGNATURE: () => t("booking_delivery_error_missingSignatures", "This booking requires all documents to be signed before delivery."),
        BOOKING_STATUS_UPDATE_WITHOUT_ITEMS: () => t("booking_statusUpdate_error_noItems", "In order to change the status of a booking, it needs to have at least one product."),
        CHANGE_PASSWORD_INVALID_OLD_PASSWORD: () => t("user_changePassword_invalidOldPassword", "The old password you provided is invalid"),
        DELETING_PRODUCT_WITH_STOCK_ITEMS: () => t("product_delete_error_stockItemsExist", "Deleting this product is forbidden because there are items in stock for it. Delete the items first."),
        STOCK_ITEM_IDENTIFIER_ALREADY_EXISTS: () => t("stockItems_update_error_identifierExists", "The identifier for this stock item is already assigned to a different item. Duplicate identifiers are not allowed."),
        BOOKING_DIFFERENT_FIXED_START_TIME_ALREADY_SET: () => t("booking_addItem_differentFixedStartTimeAlreadySet", "A period with a different fixed start time is already added to this booking. Booking items with different fixed start times are not allowed. Create a different booking for this item."),
        PRODUCT_IDENTIFIABLE_CHANGE_WITH_EXISTING_STOCK_ITEMS: () => t("product_update_changeIdentifiable_error_stockItemsExist", "Changing the Identification type of a product is only allowed if there are no stock items in the inventory."),
        BOOKING_RETURN_DATE_WHEN_CLOSED: () => t("booking_reschedule_error_returnDateWhenClosed", "The return is scheduled for a date when the facility is closed"),
        BOOKING_DELETE_WITH_DOCUMENTS_WITH_PO_NUMBERS: () => t("booking_delete_error_documentsWithPoNumbers", "This booking cannot be deleted because it is related to accounting documents"),
        PAYMENT_PROVIDER_STRIPE_NOT_CONNECTED: () => t("store_payments_notConnected", "No payment gateway found"),
        PAYMENT_PROVIDER_STRIPE_API_ERROR: () => t("store_payments_apiError", "An error occurred while contacting Stripe"),
        PAYMENT_MINIMUM_NOT_RESPECTED: (data) => t("store_paymentLink_error_minimumPriceNotRespected", "The minimum price of {{minimum}} is not respected.", data),
        UNDEFINED_PRICE_FOR_VARIANT: (data) => t("booking_addItems_error_noPriceDefined", "No price is set for {{period}} of product {{product}}.", data),
        USER_CREATION_EMAIL_USED: () => t("settings_users_create_error_emailAlreadyUsed", "A user with the same email already exists in Pulso."),
        PRICES_UPDATE_REQUEST_TOO_BIG: () => t("product_prices_error_request_too_big", "We were unable to update your prices, please, contact Pulso support to resolve this issue."),
    };
    return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resolve: (error) => {
            if (isConflictingStateError(error)) {
                return conflictingStateErrorTranslations[error.code](error.data);
            }
            const err = error.body;
            if (isConflictingStateError(err)) {
                return conflictingStateErrorTranslations[err.code](err.data);
            }
            if (error instanceof Error) {
                return error.message;
            }
            return "Server Error";
        },
    };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isConflictingStateError(error) {
    return "error" in error && error.error === "ConflictingStateError";
}
