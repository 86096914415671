import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useHospedajeSettings(facilityId: string | undefined) {
  const query = useQuery({
    queryKey: ["hospedaje-settings", facilityId],
    queryFn: async () => {
      if (!facilityId) {
        return null;
      }
      return await api.getHospedajeSettings({ facilityId }).then((res) => {
        if (res.enabled && !res.mapping) {
          return {
            ...res,
            mapping: {
              "contrato.referencia": "$.reference",
              "contrato.fechaContrato": "$.deliveredAt",
              "contrato.fechaRecogida": "$.deliveredAt",
              "contrato.pago.tipoPago": "$.invoice.payments[0].method",
              "contrato.fechaDevolucion": "$.returnedAt",
              "personas[0].datosPersona.nombre": "$.customer.firstname",
              "personas[1].datosPersona.nombre": "$.customer.firstname",
              "personas[0].datosPersona.apellido1": "$.customer.lastname",
              "personas[1].datosPersona.apellido1": "$.customer.lastname",
              "contrato.codigoEstablecimientoRecogida": "0000000000",
              "contrato.codigoEstablecimientoDevolucion": "0000000000",
            } as Record<string, string>,
          };
        }

        return {
          ...res,
          mapping: res.mapping as Record<string, string>,
        };
      });
    },
  });

  return {
    settings: query.data || null,
    isLoading: query.isLoading,
  };
}
