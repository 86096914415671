import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";
import { useState } from "react";

export function useProductPricesUpdate(productId: string) {
  const [progress, setProgress] = useState(0);

  const mutation = useMutation({
    mutationFn: async (data: ApiObjects["PricesUpdateBody"]) => {
      setProgress(0);
      let i = 0;
      for (const variantPrices of data.variantPrices) {
        await api.updatePrices(
          { productId },
          {
            variantPrices: [variantPrices],
          }
        );
        await new Promise((resolve) => setTimeout(resolve, 100));
        i++;
        setProgress(Math.floor((100 * i) / data.variantPrices.length));
      }
      setProgress(0);
    },
    invalidateKeys: [["bookings"], ["stock-items"], ["products"]],
    successMessage: "SAVE_SUCCESS",
  });

  return {
    mutation,
    progress,
  };
}
