export interface ICustomHeaderParams {
  label: string;
  seasons: { id: string; name: string }[];
  hideSelector?: boolean;
  tooltip: string;
  onSeasonAdd: (seasonId: string) => void;
}

export class PricingGroupHeaderWithSeasonsSelector {
  private eGui!: HTMLDivElement;
  private agParams!: ICustomHeaderParams;
  private selector: HTMLSelectElement | null = null;
  private handler: ((event: Event) => void) | null = null;

  init(agParams: ICustomHeaderParams) {
    this.agParams = agParams;
    this.eGui = document.createElement("div");
    const shouldHideSelector = this.agParams.hideSelector || this.agParams.seasons.length === 0;

    this.eGui.innerHTML = `
      <div class="pricing-group-header-with-seasons-selector">
        <div class="pricing-group-header-with-seasons-selector__label">${this.agParams.label}</div>
        <div ${shouldHideSelector ? "style='display: none'" : ""} class="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ellipsis-vertical"><circle cx="12" cy="12" r="1"/><circle cx="12" cy="5" r="1"/><circle cx="12" cy="19" r="1"/></svg>
          <select class="season-selector" title="${this.agParams.tooltip}">
            <option value="">&nbsp;</option>
            ${(this.agParams.seasons || []).map((s) => `<option value="${s.id}">${s.name}</option>`).join("")}
          </select>
        </div>
      </div>
    `;
    this.selector = this.eGui.querySelector(".season-selector");
    if (this.selector) {
      this.selector.value = "";
      this.handler = (e) => {
        const seasonId = (e.target as typeof this.selector).value;
        this.agParams.onSeasonAdd(seasonId);
        // Reset the value to the default, to show the button title
        if (this.selector) {
          this.selector.value = "";
        }
      };
      this.selector.addEventListener("change", this.handler);
    }
  }

  destroy() {
    if (this.selector && this.handler) {
      this.selector.removeEventListener("change", this.handler);
    }
  }

  getGui() {
    return this.eGui;
  }
}
