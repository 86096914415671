export const requiredColumns = {
  columns: [
    {
      key: "contrato.codigoEstablecimientoRecogida",
      label: "Código establecimiento (recogida)",
      value: "0000000000",
      group: "Contrato",
      mandatory: true,
    },
    {
      key: "contrato.codigoEstablecimientoDevolucion",
      label: "Código establecimiento (devolución)",
      value: "0000000000",
      group: "Contrato",
      mandatory: true,
    },
    {
      key: "contrato.referencia",
      label: "Referencia del contrato",
      value: "texto",
      autoMapping: [/referencia/],
      group: "Contrato",
      gridCellKey: "contrato.referencia",
      mandatory: true,
    },
    {
      key: "contrato.fechaContrato",
      label: "Fecha del contrato",
      value: "2024-12-05+01:00",
      autoMapping: [/^fecha$/],
      group: "Contrato",
      gridCellKey: "contrato.fechaContrato",
      mandatory: true,
      format: "date",
    },
    {
      key: "contrato.fechaRecogida",
      label: "Fecha de recogida",
      value: "2024-12-05T09:17:23.462+01:00",
      autoMapping: [/fecha.*recogida/],
      group: "Contrato",
      gridCellKey: "contrato.fechaRecogida",
      mandatory: true,
      format: "dateTime",
    },
    {
      key: "contrato.fechaDevolucion",
      label: "Fecha de devolución",
      value: "2024-12-05T09:17:23.462+01:00",
      autoMapping: [/fecha.*devolución/],
      group: "Contrato",
      gridCellKey: "contrato.fechaDevolucion",
      mandatory: true,
      format: "dateTime",
    },
    {
      key: "contrato.pago.tipoPago",
      label: "Tipo de pago",
      value: "EFECT",
      autoMapping: [/fecha.*devolución/],
      group: "Pago",
      gridCellKey: "pago",
      mandatory: true,
    },
    {
      key: "contrato.pago.fechaPago",
      label: "Fecha de pago",
      value: "2024-12-05+01:00",
      group: "Pago",
      gridCellKey: "pago",
    },
    {
      key: "contrato.pago.medioPago",
      label: "Medio de pago",
      value: "texto",
      group: "Pago",
      gridCellKey: "pago",
    },
    {
      key: "contrato.pago.titular",
      label: "Titular del pago",
      value: "texto",
      group: "Pago",
      gridCellKey: "pago",
    },
    {
      key: "contrato.pago.caducidadTarjeta",
      label: "Caducidad de la tarjeta",
      value: "12/2027",
      group: "Pago",
      gridCellKey: "pago",
    },
    {
      key: "vehiculo.categoria",
      label: "Categoría del vehículo",
      value: "texto",
      group: "Vehiculo",
      gridCellKey: "vehiculo",
      mandatory: true,
      tooltip: "Categoría del vehículo según la clasificación de la empresa de alquiler.",
    },
    {
      key: "vehiculo.tipo",
      label: "Tipo de vehículo",
      value: "TURISMO",
      group: "Vehiculo",
      gridCellKey: "vehiculo",
      mandatory: true,
      tooltip: "Uno de FURGONETA, CAMION, AUTOBUS, TURISMO, MOTO, TRACTOR, REMOLQUE, CAMPER, CARAVANA, OTRO",
    },
    {
      key: "vehiculo.marca",
      label: "Marca del vehículo",
      value: "BMW",
      group: "Vehiculo",
      gridCellKey: "vehiculo",
      mandatory: true,
      tooltip:
        "Uno de: ABARTH, ACROSS, ADRIA, ALFA_ROMEO, APRILIA, ASTON_MART, AUDI, AUTOSTAR, BENIMAR, BENTLEY, BLUCAMP, BMW, BUNKERVAN, BURSTNER, CADILLAC, CAMPER, CARADO, CARAVELAIR, CARTHAGO, CATERHAM, CHALLENGER, CHEVROLET, CITROEN, CLEVERVANS, CONCORDE, DACIA, DETHLEFFS, DREAMER, DUCATI, ELNAGH, ERIBA, ETRUSCO, FENDT, FERRARI, FIAT, FLEURETTE, FORD, FRANKIA, FURGOKARAV, GIOTTILINE, HARLEY, HOBBY, HONDA, HYMER, HYUNDAI, ILUSION, INFINITY, ISUZU, ITINEO, IVECO, JAGUAR, JEEP, JOA_CAMP, KAWASAKI, KIA, KNAUS, KTM, LADA, LAIKA, LAMBORGHIN, LANCIA, LAND_ROVER, LEVOYAGEUR, LEXUS, LOTUS, MALIBU, MASERATI, MAZDA, MCLOUIS, MERCEDES, MINI, MITSUBISHI, MOBILVETTA, MOOVEO, MORELO, MORGAN, NISSAN, NORANTZ, OPEL, OTRO, PANAMA, PEUGEOT, PHOENIX, PIAGGIO, PILOTE, PORSCHE, POSSL, RAPIDO, RENAULT, RIMOR, ROLLERTEAM, ROLLS_ROYC, SEAT, SILENCE, SILVER, SKODA, SMART, SSANGYONG, STERCKEMAN, SUBARU, SUN_LIVING, SUNLIGHT, SUZUKI, TABBERT, TATA, TESLA, TOYOTA, TRIUMPH, VOLKSWAGEN, VOLVO, WEINSBERG, WESTALIA, YAMAHA",
    },
    {
      key: "vehiculo.modelo",
      label: "Modelo del vehículo",
      value: "texto",
      group: "Vehiculo",
      gridCellKey: "vehiculo",
      mandatory: true,
    },
    {
      key: "vehiculo.matricula",
      label: "Matrícula del vehículo",
      value: "texto",
      group: "Vehiculo",
      gridCellKey: "vehiculo",
      mandatory: true,
    },
    {
      key: "vehiculo.numeroBastidor",
      label: "Número de bastidor",
      value: "texto",
      group: "Vehiculo",
      gridCellKey: "vehiculo.numeroBastidor",
      mandatory: true,
    },
    {
      key: "vehiculo.color",
      label: "Color del vehículo",
      value: "BLANCO",
      group: "Vehiculo",
      gridCellKey: "vehiculo",
      mandatory: true,
      tooltip:
        "Uno de: BLANCO, GRIS, NEGRO, PLATA, AZUL, ROJO, MARRON, BEIGE, VERDE, AMARILLO, NARANJA, ROSA, PURPURA, TURQUESA, MOSTAZA, SALMON, CIAN, LILA, GRANATE, OLIVA, MARINO, OTRO",
    },
    {
      key: "vehiculo.kmRecogida",
      label: "Kilómetros de recogida",
      value: 1,
      group: "Vehiculo",
      gridCellKey: "vehiculo.kmRecogida",
      mandatory: true,
    },
    {
      key: "vehiculo.kmDevolucion",
      label: "Kilómetros de devolución",
      value: 1,
      group: "Vehiculo",
      gridCellKey: "vehiculo.kmDevolucion",
    },
    {
      key: "vehiculo.datosGps",
      label: "Datos GPS",
      value: "texto",
      group: "Vehiculo",
      gridCellKey: "vehiculo.datosGps",
    },
    {
      key: "personas[0].datosPersona.nombre",
      label: "Nombre Titular",
      value: "texto",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
    },
    {
      key: "personas[0].datosPersona.apellido1",
      label: "Primer apellido titular",
      value: "texto",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
    },
    {
      key: "personas[0].datosPersona.apellido2",
      label: "Segundo apellido titular",
      value: "texto",
      group: "Titular",
      gridCellKey: "titular",
      tooltip: "Obligatorio si el tipo de documento es NIF",
    },
    {
      key: "personas[0].datosPersona.tipoDocumento",
      label: "Tipo de documento titular",
      value: "NIF",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
      tooltip: "Obligatorio si la persona es mayor de edad. Tiene que ser uno de: NIF, NIE, PAS, OTRO",
      format: "tipoDocumento",
    },
    {
      key: "personas[0].datosPersona.numeroDocumento",
      label: "Número de documento titular",
      value: "00000000T",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
    },
    {
      key: "personas[0].datosPersona.fechaNacimiento",
      label: "Fecha de nacimiento titular",
      value: "2024-12-05+01:00",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
      format: "date",
    },
    {
      key: "personas[0].datosPersona.nacionalidad",
      label: "Nacionalidad titular",
      value: "ESP",
      group: "Titular",
      gridCellKey: "titular",
    },
    {
      key: "personas[0].datosPersona.sexo",
      label: "Sexo titular",
      value: "H",
      group: "Titular",
      gridCellKey: "titular",
    },
    {
      key: "personas[0].datosPersona.direccion.direccion",
      label: "Dirección titular",
      value: "texto",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
    },
    {
      key: "personas[0].datosPersona.direccion.direccionComplementaria",
      label: "Dirección complementaria titular",
      value: "texto",
      group: "Titular",
      gridCellKey: "titular",
    },
    {
      key: "personas[0].datosPersona.direccion.codigoMunicipio",
      label: "Código del municipio titular",
      value: "00000",
      group: "Titular",
      gridCellKey: "titular",
      tooltip: "Obligatorio indicar el campo codigoMunicipio o nombreMunicipio",
    },
    {
      key: "personas[0].datosPersona.direccion.nombreMunicipio",
      label: "Nombre del municipio titular",
      value: "texto",
      group: "Titular",
      gridCellKey: "titular",
      tooltip: "Obligatorio indicar el campo codigoMunicipio o nombreMunicipio",
    },
    {
      key: "personas[0].datosPersona.direccion.codigoPostal",
      label: "Código postal titular",
      value: "00000",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
    },
    {
      key: "personas[0].datosPersona.direccion.pais",
      label: "País titular",
      value: "ESP",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
      format: "pais",
    },
    {
      key: "personas[0].datosPersona.telefono",
      label: "Teléfono titular",
      value: "999999999",
      group: "Titular",
      gridCellKey: "titular",
      tooltip: "Obligatorio incluir una de estas tres etiquetas: telefono, telefono2 o correo",
    },
    {
      key: "personas[0].datosPersona.telefono2",
      label: "Teléfono 2 titular",
      value: "999999999",
      group: "Titular",
      gridCellKey: "titular",
      tooltip: "Obligatorio incluir una de estas tres etiquetas: telefono, telefono2 o correo",
    },
    {
      key: "personas[0].datosPersona.correo",
      label: "Correo titular",
      value: "correo@correo.es",
      group: "Titular",
      gridCellKey: "titular",
      tooltip: "Obligatorio incluir una de estas tres etiquetas: telefono, telefono2 o correo",
    },
    {
      key: "personas[0].permisoConducir.tipo",
      label: "Tipo de permiso",
      value: "A1",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
      tooltip: "Uno de: AM, AML, A1, A2, A, B, BE, C1, C1E, C, CE, D1, D1E, D, DE, LCM, LVA, ADR, PI, OT",
    },
    {
      key: "personas[0].permisoConducir.validez",
      label: "Validez del permiso",
      value: "2024-12-05+01:00",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
      format: "date",
    },
    {
      key: "personas[0].permisoConducir.numero",
      label: "Número del permiso",
      value: "texto",
      group: "Titular",
      gridCellKey: "titular",
      mandatory: true,
    },
    {
      key: "personas[0].permisoConducir.soporte",
      label: "Soporte del permiso",
      value: "texto",
      group: "Titular",
      gridCellKey: "titular",
    },
    {
      key: "personas[1].datosPersona.nombre",
      label: "Nombre",
      value: "texto",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
    },
    {
      key: "personas[1].datosPersona.apellido1",
      label: "Primer apellido",
      value: "texto",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
    },
    {
      key: "personas[1].datosPersona.apellido2",
      label: "Segundo apellido",
      value: "texto",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      tooltip: "Obligatorio si el tipo de documento es NIF",
    },
    {
      key: "personas[1].datosPersona.tipoDocumento",
      label: "Tipo de documento",
      value: "NIF",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
      tooltip: "Obligatorio si la persona es mayor de edad. Tiene que ser uno de: NIF, NIE, PAS, OTRO",
      format: "tipoDocumento",
    },
    {
      key: "personas[1].datosPersona.numeroDocumento",
      label: "Número de documento",
      value: "00000000T",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
    },
    {
      key: "personas[1].datosPersona.fechaNacimiento",
      label: "Fecha de nacimiento",
      value: "2024-12-05+01:00",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
    },
    {
      key: "personas[1].datosPersona.nacionalidad",
      label: "Nacionalidad",
      value: "ESP",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
    },
    {
      key: "personas[1].datosPersona.sexo",
      label: "Sexo",
      value: "H",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
    },
    {
      key: "personas[1].datosPersona.direccion.direccion",
      label: "Dirección",
      value: "texto",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
    },
    {
      key: "personas[1].datosPersona.direccion.direccionComplementaria",
      label: "Dirección complementaria",
      value: "texto",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
    },
    {
      key: "personas[1].datosPersona.direccion.codigoMunicipio",
      label: "Código del municipio",
      value: "00000",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      tooltip: "Obligatorio indicar el campo codigoMunicipio o nombreMunicipio",
    },
    {
      key: "personas[1].datosPersona.direccion.nombreMunicipio",
      label: "Nombre del municipio",
      value: "texto",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      tooltip: "Obligatorio indicar el campo codigoMunicipio o nombreMunicipio",
    },
    {
      key: "personas[1].datosPersona.direccion.codigoPostal",
      label: "Código postal",
      value: "00000",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
    },
    {
      key: "personas[1].datosPersona.direccion.pais",
      label: "País",
      value: "ESP",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
      format: "pais",
    },
    {
      key: "personas[1].datosPersona.telefono",
      label: "Teléfono",
      value: "999999999",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      tooltip: "Obligatorio incluir una de estas tres etiquetas: telefono, telefono2 o correo",
    },
    {
      key: "personas[1].datosPersona.telefono2",
      label: "Teléfono 2",
      value: "999999999",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      tooltip: "Obligatorio incluir una de estas tres etiquetas: telefono, telefono2 o correo",
    },
    {
      key: "personas[1].datosPersona.correo",
      label: "Correo",
      value: "correo@correo.es",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      tooltip: "Obligatorio incluir una de estas tres etiquetas: telefono, telefono2 o correo",
    },
    {
      key: "personas[1].permisoConducir.tipo",
      label: "Tipo de permiso de conducir",
      value: "A1",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
      tooltip: "Uno de: AM, AML, A1, A2, A, B, BE, C1, C1E, C, CE, D1, D1E, D, DE, LCM, LVA, ADR, PI, OT",
    },
    {
      key: "personas[1].permisoConducir.validez",
      label: "Validez del permiso de conducir",
      value: "2024-12-05+01:00",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
      format: "date",
    },
    {
      key: "personas[1].permisoConducir.numero",
      label: "Número del permiso de conducir",
      value: "texto",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
      mandatory: true,
    },
    {
      key: "personas[1].permisoConducir.soporte",
      label: "Soporte del permiso de conducir",
      value: "texto",
      group: "Conductor principal",
      gridCellKey: "conductor.principal",
    },
    {
      key: "personas[2].datosPersona.nombre",
      label: "Nombre",
      value: "texto",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
    {
      key: "personas[2].datosPersona.apellido1",
      label: "Primer apellido",
      value: "texto",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
    {
      key: "personas[2].datosPersona.apellido2",
      label: "Segundo apellido",
      value: "texto",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
      tooltip: "Obligatorio si el tipo de documento es NIF",
    },
    {
      key: "personas[2].datosPersona.tipoDocumento",
      label: "Tipo de documento",
      value: "NIF",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
      tooltip: "Obligatorio si la persona es mayor de edad. Tiene que ser uno de: NIF, NIE, PAS, OTRO",
      format: "tipoDocumento",
    },
    {
      key: "personas[2].datosPersona.numeroDocumento",
      label: "Número de documento",
      value: "00000000T",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
    {
      key: "personas[2].datosPersona.fechaNacimiento",
      label: "Fecha de nacimiento",
      value: "2024-12-05+01:00",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
    {
      key: "personas[2].datosPersona.nacionalidad",
      label: "Nacionalidad",
      value: "ESP",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
    {
      key: "personas[2].datosPersona.sexo",
      label: "Sexo",
      value: "H",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
    {
      key: "personas[2].datosPersona.direccion.direccion",
      label: "Dirección",
      value: "texto",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
    {
      key: "personas[2].datosPersona.direccion.direccionComplementaria",
      label: "Dirección complementaria",
      value: "texto",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
    {
      key: "personas[2].datosPersona.direccion.codigoMunicipio",
      label: "Código del municipio",
      value: "00000",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
      tooltip: "Obligatorio indicar el campo codigoMunicipio o nombreMunicipio",
    },
    {
      key: "personas[2].datosPersona.direccion.nombreMunicipio",
      label: "Nombre del municipio",
      value: "texto",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
      tooltip: "Obligatorio indicar el campo codigoMunicipio o nombreMunicipio",
    },
    {
      key: "personas[2].datosPersona.direccion.codigoPostal",
      label: "Código postal",
      value: "00000",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
    {
      key: "personas[2].datosPersona.direccion.pais",
      label: "País",
      value: "ESP",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
      format: "pais",
    },
    {
      key: "personas[2].datosPersona.telefono",
      label: "Teléfono",
      value: "999999999",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
      tooltip: "Obligatorio incluir una de estas tres etiquetas: telefono, telefono2 o correo",
    },
    {
      key: "personas[2].datosPersona.telefono2",
      label: "Teléfono 2",
      value: "999999999",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
      tooltip: "Obligatorio incluir una de estas tres etiquetas: telefono, telefono2 o correo",
    },
    {
      key: "personas[2].datosPersona.correo",
      label: "Correo",
      value: "correo@correo.es",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
      tooltip: "Obligatorio incluir una de estas tres etiquetas: telefono, telefono2 o correo",
    },
    {
      key: "personas[2].permisoConducir.tipo",
      label: "Tipo de permiso",
      value: "A1",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
      tooltip: "Uno de: AM, AML, A1, A2, A, B, BE, C1, C1E, C, CE, D1, D1E, D, DE, LCM, LVA, ADR, PI, OT",
    },
    {
      key: "personas[2].permisoConducir.validez",
      label: "Validez del permiso",
      value: "2024-12-05+01:00",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
      format: "date",
    },
    {
      key: "personas[2].permisoConducir.numero",
      label: "Número del permiso",
      value: "texto",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
    {
      key: "personas[2].permisoConducir.soporte",
      label: "Soporte del permiso",
      value: "texto",
      group: "Conductor secundario",
      gridCellKey: "conductor.secundario",
    },
  ],
};
