import { Column, Workbook } from "exceljs";
import { ColDef } from "ag-grid-community";
import { formatPrice } from "@pulso/utils";

export async function createExcelExportFromAgGridData(
  filename: string,
  colDefs: ColDef[],
  data: Record<string, unknown>[],
  currency: string
) {
  const contextFormats = {
    date: "dd mmm yyyy, hh:mm",
    price: formatPrice(0, currency),
    percentage: "0.00%",
  };

  const columns = colDefs.map((col) => {
    const extra: Partial<Column> = {};

    if (col.context?.export) {
      if (col.context?.export.width) {
        extra.width = col.context?.export.width;
      }
      if (col.context?.export.format) {
        extra.style = { numFmt: contextFormats[col.context.export.format as "date"] };
        if (!extra.width) {
          extra.width = 18;
        }
      }
    }
    return {
      ...extra,
      header: col.headerName,
    } as Partial<Column>;
  });
  const rows = data.map((item) =>
    colDefs.map((colDef) => {
      const value =
        typeof colDef.valueGetter === "function"
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            colDef.valueGetter({ data: item, getValue: () => (colDef.field ? item[colDef.field] : "") } as any)
          : colDef.field
            ? item[colDef.field]
            : "";

      if (colDef.context?.export?.format === "date" && typeof value === "string" && value) {
        return new Date(value);
      }

      return value;
    })
  );

  await createExcelExport(filename, columns, rows);
}

export async function createExcelExport(filename: string, columns: Partial<Column>[], rows: object[]) {
  const workbook = new Workbook();
  workbook.creator = "Pulso";
  workbook.lastModifiedBy = "Pulso";
  workbook.created = new Date();
  workbook.modified = new Date();
  const sheet = workbook.addWorksheet("Export");
  sheet.columns = columns;
  sheet.addRows(rows);

  await downloadExcel(filename, workbook);
}

export async function downloadExcel(filename: string, workbook: Workbook) {
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  const url = URL.createObjectURL(blob);

  const element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
