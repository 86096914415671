import { api } from "@/lib/api-client";
import { fieldsToHash, formatPrice, hashToVariantId } from "@pulso/utils";
import { t } from "i18next";
import { PropsWithChildren, useRef, useState } from "react";
import { useSeasons } from "@/api/useSeasons";
import "./ProductVariants.css";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ColGroupDef, GridApi, IRowNode } from "ag-grid-community";
import { ImportExportPrices } from "./ImportExportPrices";
import { PricingGroupHeaderWithSeasonsSelector } from "./PricingGroupHeaderWithSeasonsSelector";
import sumBy from "lodash/sumBy";
import { PricingHeaderWithSeasonsRemove } from "./PricingHeaderWithSeasonsRemove";
import { ApiObjects } from "@pulso/api-client";
import { Progress } from "@/components/ui/progress";
import { useHref } from "react-router-dom";
import { useWindowResizeVersion } from "@/lib/useWindowResizeVersion";

type Prices = Record<string, Record<string, number | null | undefined>>;

type ProductVariantsProps = PropsWithChildren<{
  prices: Prices;
  onSave: (prices: Prices, isValid: boolean) => void;
  product: Awaited<ReturnType<typeof api.getProduct>>;
  rentalPeriods: Awaited<ReturnType<typeof api.getAllRentalPeriodsForFacility>>;
  currency: string;
  isSaving?: boolean;
  saveProgress: number;
}>;

const COL_WIDTH = 130;

export function ProductVariantsGrid({
  prices: pricesProp,
  product,
  rentalPeriods,
  currency,
  onSave,
  isSaving,
  saveProgress,
  children,
}: ProductVariantsProps) {
  const pricesRef = useRef(pricesProp);
  const errorsRef = useRef<Record<string, string>>(validatePrices(pricesProp));
  const variants = generateVariants(product);
  const rentalPeriodsSorted = rentalPeriods.sort((a, b) => a.period - b.period);
  const hasVariants = variants.length > 1 || variants[0].hash !== "default";
  const { seasons } = useSeasons(product.facilityId);
  const hasSeasons = seasons.length > 0;
  const [selectedSeasons, setSelectedSeasons] = useState<Record<string, string[]>>(getPreSelectedSeasons(pricesProp));
  const [columns, data] = getColumnsAndData(pricesRef.current);

  const gridContainerWidth = (document.getElementById("main")?.clientWidth || 100) - 100;
  const calcGridWidth = (sumBy(columns, (c) => c.children.length) + 1) * COL_WIDTH + 10;
  const gridWidth = calcGridWidth < gridContainerWidth ? calcGridWidth : "100%";
  useWindowResizeVersion();

  const apiRef = useRef<GridApi | null>(null);
  const seasonPageHref = useHref(`../settings/rental-periods`);

  return (
    <div>
      <div className="pb-6">
        <ImportExportPrices
          periods={rentalPeriods}
          prices={pricesRef.current}
          variants={variants}
          seasons={seasons}
          product={product}
          onChange={(prices) => {
            updatePrices(prices);
            setSelectedSeasons(getPreSelectedSeasons(prices));
          }}
        />
      </div>
      {!isSaving ? (
        <div className="ag-theme-quartz h-[600px]" style={{ width: gridWidth }}>
          <AgGridReact
            columnDefs={columns}
            rowData={data}
            context={{ version: 1 }}
            defaultColDef={{
              sortable: false,
              suppressMovable: true,
              flex: 1,
              minWidth: COL_WIDTH,
            }}
            onGridReady={(e) => (apiRef.current = e.api)}
            onCellValueChanged={(event) => {
              const newPrices = { ...pricesRef.current };
              const [variantHash, seasonId] = (event.colDef?.field || "").split("_");
              const periodId = event.data.periodId;
              if (!newPrices[variantHash]) {
                newPrices[variantHash] = {};
              }
              newPrices[variantHash][`${periodId}_${seasonId}`] = event.newValue;
              updatePrices(newPrices, event.node);
            }}
          />
        </div>
      ) : (
        <div className="flex w-full h-[600px] items-center justify-center" style={{ width: gridWidth }}>
          <div className="w-64">{saveProgress ? <Progress value={saveProgress} /> : null}</div>
        </div>
      )}
      <div
        className="mt-6"
        onClick={() => {
          const isValid = Object.keys(errorsRef.current).length === 0;
          onSave(pricesRef.current, isValid);
          if (!isValid) {
            const [variantHash, seasonId, periodId] = Object.keys(errorsRef.current)[0].split("_");
            const rowIndex = rentalPeriods.findIndex((p) => p.id === periodId);
            apiRef.current?.ensureIndexVisible(rowIndex);
            apiRef.current?.ensureColumnVisible(`${variantHash}_${seasonId}`);
          }
        }}
      >
        {children}
      </div>
    </div>
  );

  function getColumnsAndData(prices: Prices) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: ColGroupDef<any>[] = [
      {
        headerName: product.name,
        children: [
          {
            headerName: t("products_pricing_season", "Season"),
            headerComponent: class {
              getGui() {
                const el = document.createElement("div");
                el.innerHTML = `<a href="${seasonPageHref}">${t("products_pricing_season", "Season")}</a>`;
                return el;
              }
            },
            pinned: "left",
            field: "periodName",
          },
        ],
        marryChildren: true,
      },
    ];

    for (const variant of variants) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const subColumns: ColDef<any>[] = [];
      for (const season of ["default", ...(selectedSeasons[variant.hash] ?? [])]) {
        subColumns.push({
          field: `${variant.hash}_${season}`,
          valueFormatter: (params) => {
            return typeof params.value === "number" ? formatPrice(params.value, currency) : "-";
          },
          cellEditor: "agNumberCellEditor",
          editable: true,
          cellStyle: (params) => {
            const key = params.colDef.field + "_" + params.data.periodId;
            if (errorsRef.current[key]) {
              return {
                border: "1px solid red",
                backgroundColor: "rgb(254 226 226)",
                borderRadius: "7px",
              };
            }
          },
          cellEditorParams: {
            min: 0,
          },
          headerComponent: PricingHeaderWithSeasonsRemove,
          headerComponentParams: {
            hideRemove: season === "default",
            onRemove: () => {
              setSelectedSeasons((prev) => ({
                ...prev,
                [variant.hash]: prev[variant.hash].filter((seasonId) => seasonId !== season),
              }));

              const newPrices = { ...prices };

              for (const key in newPrices[variant.hash]) {
                const [, seasonId] = key.split("_");
                if (seasonId === season) {
                  newPrices[variant.hash][key] = null;
                }
              }
              updatePrices(newPrices);
            },
            tooltip: t("products_pricing_removeSeason_button", "Remove season"),
            label:
              season === "default"
                ? !hasSeasons
                  ? t("products_pricing_noSeasons_defaultVariant", "All season")
                  : t("products_pricing_defaultVariant", "Default")
                : seasons.find((s) => s.id === season)?.name,
          },
        });
      }
      columns.push({
        autoHeaderHeight: true,
        children: subColumns,
        wrapHeaderText: true,
        headerGroupComponent: PricingGroupHeaderWithSeasonsSelector,
        headerGroupComponentParams: {
          label: variant.hash === "default" ? "" : variant.label,
          seasons: seasons
            .filter((s) => !(selectedSeasons[variant.hash] ?? []).includes(s.id))
            .map((s) => ({
              id: s.id,
              name: t("products_pricing_addSeason_button", "Add {{season}}", { season: s.name }),
            })),
          hideSelector: !hasSeasons,
          tooltip: t("products_pricing_addSeason_button", "Add season"),
          onSeasonAdd: (seasonId: string) => {
            setSelectedSeasons((prev) => ({
              ...prev,
              [variant.hash]: [...prev[variant.hash], seasonId],
            }));
          },
        },
      });
    }

    const data: Record<string, number | string | null>[] = [];
    for (const period of rentalPeriodsSorted) {
      const row: Record<string, number | string | null> = {};
      row.periodId = period.id;
      row.periodName = period.name;
      for (const variant of variants) {
        for (const season of ["default", ...(selectedSeasons[variant.hash] ?? [])]) {
          const price = prices[variant.hash]?.[`${period.id}_${season}`];
          row[`${variant.hash}_${season}`] = price ?? null;
        }
      }
      data.push(row);
    }

    return [columns, data] as const;
  }

  function updatePrices(prices: Prices, node?: IRowNode | null) {
    pricesRef.current = prices;
    errorsRef.current = validatePrices(prices);
    apiRef.current?.redrawRows(node ? { rowNodes: [node] } : undefined);
  }
}

function validatePrices(prices: Prices) {
  const variantPeriodHash: Record<string, boolean> = {};
  for (const [variantHash, periods] of Object.entries(prices)) {
    for (const periodIdSeasonIdPair in periods as object) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const price = (periods as any)[periodIdSeasonIdPair];
      if (typeof price !== "undefined" && price !== null) {
        const [periodId, seasonId] = periodIdSeasonIdPair.split("_");
        if (!variantPeriodHash[`${variantHash}_${periodId}`]) {
          variantPeriodHash[`${variantHash}_${periodId}`] = false;
        }
        variantPeriodHash[`${variantHash}_${periodId}`] ||= seasonId === "default";
      }
    }
  }
  const errors: Record<string, string> = {};
  const missingDefaults = Object.keys(variantPeriodHash).filter((key) => !variantPeriodHash[key]);
  for (const variantHashPeriodIdPair of missingDefaults) {
    const [variantHash, periodId] = variantHashPeriodIdPair.split("_");
    errors[`${variantHash}_default_${periodId}`] = t(
      "product_prices_error_missingDefaultValue",
      "Default value needs to be specified"
    );
  }
  return errors;
}

function generateVariants(product: ApiObjects["ProductWithExtraDto"]) {
  const enabledFields = product.fields.filter((f) => f.priceable);

  const permutations = createVariantsPermutations(enabledFields.map((field) => field?.options || []));

  const variants = permutations.map((variant) => {
    const hash = fieldsToHash(variant.map((v) => ({ value: v.id })));

    return {
      id: hashToVariantId(product.id, hash),
      label: variant.map((o) => o.value).join(" / "),
      hash,
    };
  });

  if (permutations.length === 1 && permutations[0].length === 0) {
    return [
      {
        id: product.id + "_default",
        hash: "default",
        label: t("products_pricing_defaultVariant", "Default"),
      },
    ];
  }

  return variants;
}

function createVariantsPermutations<T>(arrays: T[][]): T[][] {
  return arrays.reduce<T[][]>(
    (acc, current) => {
      const result: T[][] = [];
      acc.forEach((accItem) => {
        current.forEach((currItem) => {
          result.push([...accItem, currItem]);
        });
      });
      return result;
    },
    [[]]
  );
}

function getPreSelectedSeasons(prices: Prices) {
  const seasonPerVariant: Record<string, string[]> = {};
  for (const variantHash in prices) {
    const seasons: Record<string, true> = {};
    for (const periodIdSeasonIdPair in prices[variantHash]) {
      if (prices[variantHash][periodIdSeasonIdPair] !== null) {
        const [periodId, seasonId] = periodIdSeasonIdPair.split("_");
        if (seasonId !== "default") {
          seasons[seasonId] = true;
        }
      }
    }
    seasonPerVariant[variantHash] = Object.keys(seasons);
  }
  return seasonPerVariant;
}
