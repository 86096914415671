import { useProductPricesUpdate } from "@/api/useProductPricesUpdate";
import { Button } from "@/components/ui/button";
import { ApiObjects } from "@pulso/api-client";
import { t } from "i18next";
import { useMemo } from "react";
import { ProductVariantsGrid } from "./ProductVariantsGrid";

type ProductPricingFormProps = {
  product: ApiObjects["ProductWithExtraDto"];
  rentalPeriods: ApiObjects["RentalPeriodDto"][];
  variantPrices: ApiObjects["ProductPricesGetResponse"]["prices"];
  currency: string;
};

export function ProductPricingForm({ product, rentalPeriods, variantPrices, currency }: ProductPricingFormProps) {
  const prices = useMemo(() => getAdaptedVariantPrices(), [product, rentalPeriods, variantPrices]);

  const { mutation: saveVariantsMutation, progress } = useProductPricesUpdate(product.id);

  return (
    <div className="relative">
      <div className="min-w-[400px]">
        <ProductVariantsGrid
          prices={prices}
          onSave={onSubmit}
          product={product}
          rentalPeriods={rentalPeriods}
          currency={currency}
          isSaving={saveVariantsMutation.isPending}
          saveProgress={progress}
        >
          <Button disabled={saveVariantsMutation.isPending}>{t("common_button_save")}</Button>
        </ProductVariantsGrid>
      </div>
    </div>
  );

  function onSubmit(newPrices: typeof prices, isValid: boolean) {
    if (!isValid) {
      return;
    }

    const variantPrices: ApiObjects["PricesUpdateBody"]["variantPrices"] = [];

    for (const [variantHash, periods] of Object.entries(newPrices)) {
      const periodPrices = Object.entries(periods as never).map(([periodIdSeasonIdPair, price]) => {
        const [periodId, seasonId] = periodIdSeasonIdPair.split("_");
        return {
          periodId,
          seasonId,
          price: price === "" ? null : parseFloat(price + "") < 0 ? null : parseFloat(price + ""),
        };
      });

      if (periodPrices.length > 0) {
        variantPrices.push({
          hash: variantHash,
          periodPrices,
        });
      }
    }

    saveVariantsMutation.mutate({
      variantPrices,
    });
  }

  function getAdaptedVariantPrices() {
    return variantPrices.reduce((obj, variant) => {
      return {
        ...obj,
        [variant.hash]: variant.periodPrices.reduce(
          (periodsObj, period) => ({ ...periodsObj, [period.periodId + "_" + period.seasonId]: period.price }),
          {}
        ),
      };
    }, {});
  }
}
