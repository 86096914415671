import { useBookingAssignItem } from "@/api/useBookingAssignItem";
import { useBookingAvailableStockItems } from "@/api/useBookingAvailableStockItems";
import { useProduct } from "@/api/useProduct";
import { Notes } from "@/components/specific/Notes";
import { Badge } from "@/components/ui/badge";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Spinner } from "@/components/ui/spinner";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { StockItemStatus } from "@/pages/inventory/StockItemStatus";
import { ApiObjects } from "@pulso/api-client";
import { t } from "i18next";
import { PropsWithChildren, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDebounce } from "use-debounce";
import { AssignedStockItems } from "./AssignedStockItems";
import { ButtonCallback } from "@pulso/components/lib/ButtonCallback";
import { useLocations } from "@/api/useLocations";

type NewBookingItemModalProps = PropsWithChildren<{
  items: ApiObjects["BookingItemDto"][];
  productId: string;
  facilityId: string;
}>;

export function AssignStockItemModal(props: NewBookingItemModalProps) {
  const unassignedBookingItems = props.items.filter((i) => !i.stockItem);
  const bookingItemId = unassignedBookingItems[0]?.id;
  const [open, onOpenChange] = useState(false);

  return (
    <>
      <AssignedStockItems items={props.items} groupUnassigned onUnassignedClick={() => onOpenChange(true)} />
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent
          size="4xl"
          id="dialog"
          className="lg:w-[600px] xl:w-[800px]"
          style={{ gridTemplateRows: "auto 1fr" }}
        >
          <DialogHeader>
            <DialogTitle>{t("bookings_items_assign_title", "Assign item")}</DialogTitle>
            <DialogDescription>
              {t(
                "bookings_items_assign_subtitle",
                "Select an item from the list below to assign to this booking. The item will be blocked for the selected period."
              )}
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col overflow-hidden">
            <div className="mb-3">
              <AssignedStockItems items={props.items} noPortal />
            </div>
            {open && bookingItemId && (
              <StockItemsList
                {...props}
                onAssigned={() => {
                  if (unassignedBookingItems.length === 1) {
                    onOpenChange(false);
                  }
                }}
                bookingItemId={bookingItemId}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

type StockItemsListProps = NewBookingItemModalProps & { bookingItemId: string; onAssigned: () => void };

export function StockItemsList({ productId, bookingItemId, onAssigned, facilityId }: StockItemsListProps) {
  const product = useProduct(productId);
  const assign = useBookingAssignItem(bookingItemId);
  const [queryRaw, setQuery] = useState("");
  const [query] = useDebounce(queryRaw, 500);

  const stockItems = useBookingAvailableStockItems(bookingItemId, query);
  const { isLocationsEnabled, locationsMap } = useLocations(facilityId);

  if (stockItems.isLoading) {
    return <Spinner />;
  }

  return (
    <div className="overflow-hidden flex-1 flex flex-col">
      <div className="mb-3" style={{ padding: "1px" }}>
        <Input
          value={queryRaw}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t("common_placeholder_search")}
          autoFocus={true}
        />
      </div>
      {stockItems.items.length ? (
        <>
          <div id="scrollableDiv" className="overflow-y-auto flex-1 lg:max-h-[25.5rem]">
            <InfiniteScroll
              loader={<CentralSpinner />}
              style={{ overflow: "hidden" }}
              scrollableTarget="scrollableDiv"
              {...stockItems}
            >
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t("stockItems_table_header_identifier", "Identifier")}</TableHead>
                    <TableHead className="hidden sm:table-cell">
                      {t("stockItems_table_header_status", "Status")}
                    </TableHead>
                    <TableHead>{t("product_fields_title", "Fields")}</TableHead>
                    <TableHead>{t("stockItems_table_header_notes", "Notes")}</TableHead>
                    <TableHead></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {stockItems.items.map((stockItem) => (
                    <TableRow key={stockItem.id}>
                      <TableCell>
                        <Badge variant="secondary">{stockItem.identifier}</Badge>
                      </TableCell>
                      <TableCell className="hidden sm:table-cell">
                        <StockItemStatus status={stockItem.status} from={stockItem.from} />
                      </TableCell>
                      {isLocationsEnabled && (
                        <TableCell>
                          {stockItem.currentLocationId ? locationsMap[stockItem.currentLocationId].name : "??"}
                        </TableCell>
                      )}
                      <TableCell>
                        <div className="-mt-1 -ml-1">
                          {product.data?.fields.map((field) => (
                            <Badge variant="outline" key={field.id} className="mt-1 ml-1" title={field.name}>
                              {stockItem.fields.find((f) => field.id === f.fieldId)?.value || "-"}
                            </Badge>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="max-w-16 sm:max-w-32">
                          <Notes iconOnly={false} notes={stockItem.notes} id={stockItem.id} noPortal />
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <ButtonCallback
                          variant="outline"
                          size="sm"
                          onClick={() => assign.mutateAsync(stockItem.id, { onSuccess: onAssigned })}
                        >
                          <span className="hidden sm:inline">{t("bookings_items_assign_button", "Assign")}</span>
                          <span className="sm:hidden">+</span>
                        </ButtonCallback>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </div>
        </>
      ) : (
        <div className="text-muted-foreground text-sm">
          {t("bookings_items_assign_table_empty", "No items from the inventory are assignable.")}
        </div>
      )}
    </div>
  );
}
