export interface ICustomHeaderParams {
  label: string;
  seasons: { id: string; name: string }[];
  hideRemove?: boolean;
  tooltip: string;
  onRemove: () => void;
}

export class PricingHeaderWithSeasonsRemove {
  private eGui!: HTMLDivElement;
  private agParams!: ICustomHeaderParams;
  private button: HTMLElement | null = null;
  private handler: ((event: Event) => void) | null = null;

  init(agParams: ICustomHeaderParams) {
    this.agParams = agParams;
    this.eGui = document.createElement("div");

    this.eGui.innerHTML = `
      <div class="pricing-header-with-seasons-remove">
        <div class="pricing-header-with-seasons-remove__label">${this.agParams.label}</div>
        ${
          !this.agParams.hideRemove
            ? `
          <a href="javascript://" title=${this.agParams.tooltip} class="pricing-header-with-seasons-remove__remove-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
          </a>
        `
            : ""
        }
      </div>
    `;
    this.button = this.eGui.querySelector(".pricing-header-with-seasons-remove__remove-button");
    if (this.button) {
      this.handler = (e) => {
        this.agParams.onRemove();
      };
      this.button.addEventListener("click", this.handler);
    }
  }

  destroy() {
    if (this.button && this.handler) {
      this.button.removeEventListener("click", this.handler);
    }
  }

  getGui() {
    return this.eGui;
  }
}
