import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useSeasonUpdate() {
  return useMutation({
    mutationFn: async (data: { id: string; name: string; from: number; to: number }) => {
      await api.updateSeason(
        { seasonId: data.id },
        {
          name: data.name,
          from: data.from,
          to: data.to,
        }
      );
    },
    successMessage: "SAVE_SUCCESS",
    invalidateKeys: [["seasons"]],
  });
}
