import { Info } from "lucide-react";
import { TooltipSimple } from "../ui/tooltip";
import { cn } from "@pulso/components/lib/utils";

export function InfoBox(props: { text: string; label?: string; className?: string; size?: number }) {
  return (
    <TooltipSimple text={props.text} className={cn("flex items-center", props.className)}>
      {props.label && <span className="pr-1">{props.label}</span>}
      <Info size={props.size || 18} strokeWidth={2} className="shrink-0" />
    </TooltipSimple>
  );
}
