import { Notebook } from "lucide-react";
import { Popover, PopoverTrigger, PopoverContent } from "../ui/popover";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { useStockItemPatch } from "@/api/useStockItemPatch";
import { t } from "i18next";

export function Notes({
  notes,
  iconOnly,
  id,
  onSave,
  iconSize,
  noPortal,
}: {
  notes: string;
  iconOnly?: boolean;
  id: string;
  onSave?: (notes: string) => void;
  iconSize?: number;
  noPortal?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [newNotes, setNewNotes] = useState(notes);
  const patch = useStockItemPatch(id);

  useEffect(() => {
    setNewNotes(notes);
  }, [open]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger onClick={(e) => e.stopPropagation()} className="block">
        <div className={cn("flex items-center w-full max-w-48", !notes && "text-muted-foreground")}>
          <div className="cursor-pointer">
            <Notebook className={cn(!iconOnly && "mr-1")} size={iconSize || 18} strokeWidth={1.2} />
          </div>
          {!iconOnly && (
            <div className="w-full max-w-sm text-left text-ellipsis text-nowrap overflow-hidden">
              {notes ? notes.substring(0, 100) : "-"}
            </div>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent onClick={(e) => e.stopPropagation()} className="w-80" noPortal={noPortal}>
        <Textarea value={newNotes} onChange={(e) => setNewNotes(e.target.value)} />
        <div className="mt-3">
          <Button onClick={() => patch.mutate({ notes: newNotes }, { onSuccess: () => setOpen(false) })}>
            {t("common_button_save")}
          </Button>
          <Button className="ml-3" variant="outline" onClick={() => setOpen(false)}>
            {t("common_button_close")}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
