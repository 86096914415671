import { useProducts } from "@/api/useProducts";
import { useStoreSettings } from "@/api/useStoreSettings";
import { useStoreSettingsPatch } from "@/api/useStoreSettingsPatch";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { Form, FormField } from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { withFacilityIdParam } from "@/lib/withParams";
import { ApiObjects } from "@pulso/api-client";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectNative } from "@pulso/components/lib/SelectNative";
import { Code } from "@pulso/components/lib/Code";
import { StripeSettings } from "./StripeSettings";
import { StoreSettingsForm } from "./StoreSettingsForm";
import { useProductsWithVariants } from "@/api/useProductsWithVariants";
import { Fragment, useState } from "react";
import { cn } from "@pulso/components/lib/utils";

export const StoreSettingsPage = withFacilityIdParam((params) => {
  const { t } = useTranslation();
  const { settings, isLoading } = useStoreSettings(params.facilityId);
  const patchSettings = useStoreSettingsPatch(params.facilityId);
  const { products } = useProductsWithVariants(params.facilityId);
  const [productCodesOpen, setProductCodesOpen] = useState(false);

  const integrationScripts = {
    script: `<script src="https://app.pulsorent.com/store/store-loader.js" data-facility-id="${params.facilityId}"></script>`,
    button: `<button onclick="pulso.open('${params.facilityId}')">Book now</button>`,
    addToBasketButton: `<button onclick="pulso.addToBasket('[PRODUCT_ID]', '[VARIANT_ID]', event)">Add to basket</button>`,
    addToBasketHandler: `pulso.addToBasket('[PRODUCT_ID]', '[VARIANT_ID]', event)`,
    link: `javascript:pulso.open('${params.facilityId}')`,
    basket: `<div id="pulsoBasket" data-facility-id="${params.facilityId}"></div>`,
  };

  if (!settings || isLoading) {
    return <CentralSpinner />;
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>{t("settings_store_enable_title", "Online store")}</CardTitle>
          <CardDescription>
            {t(
              "settings_store_enable_description",
              "Enable Online Store to allow your customers to book directly from your website."
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-1">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              {t("settings_store_enable_label", "Enable Online store")}
            </label>
          </div>
          <Switch checked={settings.enabled} onCheckedChange={(c) => patchSettings.mutate({ enabled: c })}></Switch>
        </CardContent>
      </Card>
      {settings.enabled && (
        <>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_link_title", "Try out store")}</CardTitle>
              <CardDescription>
                {t("settings_store_link_description", "Open a demo store that simulates the real customer experience.")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <a
                target="_blank"
                href={
                  (process.env.REACT_APP_STORE_DEMO_URL || "") +
                  "?f=" +
                  params.facilityId +
                  "&apiUrl=" +
                  encodeURIComponent(process.env.REACT_APP_STORE_API_URL || "")
                }
              >
                <Button variant="outline">{t("settings_store_enable_title", "Open store")}</Button>
              </a>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_settings_title", "Store customization")}</CardTitle>
              <CardDescription>
                {t(
                  "settings_store_settings_description",
                  "Customzie your online store the way that best suites your business."
                )}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <StoreSettingsForm facilityId={params.facilityId} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_products_title", "Store Products")}</CardTitle>
              <CardDescription>
                {t(
                  "settings_store_products_description",
                  "Choose which products will be bookable in your online store."
                )}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <StoreProductForm facilityId={params.facilityId} settings={settings} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_payments_title", "Payments")}</CardTitle>
              <CardDescription>
                {t("settings_store_payments_description", "Connect a payment provider")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Switch
                checked={settings.paymentsEnabled}
                onCheckedChange={(c) => patchSettings.mutate({ paymentsEnabled: c })}
              ></Switch>
              {settings.paymentsEnabled && (
                <div className="pt-6">
                  <StripeSettings facilityId={params.facilityId} />
                </div>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_integrate_title", "Integrate in your webiste")}</CardTitle>
              <CardDescription>
                {t(
                  "settings_store_integrate_description",
                  "Follow the instructions to integrate the Pulso Online Store with your website."
                )}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <hr className="mb-6" />
              <div className="font-bold mb-1">{t("settings_store_integrate_button_title", "Single button wizard")}</div>
              <div className="mb-6 text-sm">
                {t(
                  "settings_store_integrate_button_subtitle",
                  "You can associate a button in your page to open the Pulso Online Store popup window where your clients can select a product and complete their booking."
                )}
              </div>

              <div className="text-sm space-y-6 pl-3">
                <div className="space-y-2">
                  <div>
                    1. {t("settings_store_integrate_addScript_title", "Add the loading script to your main page")}
                  </div>
                  <Code text={integrationScripts.script} copyButtonText={t("common_button_copy", "Copy")} />
                </div>
                <div className="space-y-2">
                  <div>2. {t("settings_store_integrate_addButton_title", 'Add or modify a "Book now" button')}</div>
                  <Code text={integrationScripts.button} copyButtonText={t("common_button_copy", "Copy")} />
                  <div>
                    {t(
                      "settings_store_integrate_addButtonHref_title",
                      "or use the following link for a button if you are using a CMS, such as WordPress: "
                    )}
                    <span className="font-mono bg-secondary/50 p-1 border rounded-md">{integrationScripts.link}</span>
                  </div>
                </div>
              </div>
              <hr className="my-6" />
              <div className="font-bold mb-1">
                {t("settings_store_integrate_button_title", "Product specific button with a basket widget")}
              </div>
              <div className="mb-6 text-sm">
                {t(
                  "settings_store_integrate_button_subtitle",
                  'This method adds a basket widget to your website. You can then assocaite multiple "Add to cart" buttons which would add products to the basket. Clients can complete their bookings by opening the basket.'
                )}
              </div>

              <div className="text-sm space-y-6 pl-3">
                <div className="space-y-2">
                  <div>
                    1. {t("settings_store_integrate_addScript_title", "Add the loading script to your main page")}
                  </div>
                  <Code text={integrationScripts.script} copyButtonText={t("common_button_copy", "Copy")} />
                </div>
                <div className="space-y-2">
                  <div>
                    2. {t("settings_store_integrate_addBasketElement_title", "Add the basket placeholder to your page")}
                  </div>
                  <Code text={integrationScripts.basket} copyButtonText={t("common_button_copy", "Copy")} />
                </div>
                <div className="space-y-2">
                  <div>
                    3.{" "}
                    {t(
                      "settings_store_integrate_addAddToBasketButton_title",
                      'Associate an "Add to basket" button with a product'
                    )}
                  </div>
                  <Code text={integrationScripts.addToBasketButton} copyButtonText={t("common_button_copy", "Copy")} />
                  <Button variant="link" className="px-0" onClick={() => setProductCodesOpen(!productCodesOpen)}>
                    {t("settings_store_integrate_productCodes_title", "See all product codes")}
                  </Button>
                  <div className={cn("flex flex-col", !productCodesOpen && "hidden")}>
                    {(products || [])
                      .filter((p) => settings.products.some((sp) => sp.productId === p.id))
                      .map((product) => (
                        <Fragment key={product.id}>
                          {product.variants.map((variant) => (
                            <div
                              key={variant.id}
                              className="hover:bg-secondary group flex items-center gap-6 border-b py-1"
                            >
                              <div className="w-96">
                                {product.name} {variant.variantName !== "default" && variant.variantName}
                              </div>
                              <div>
                                <Code
                                  text={integrationScripts.addToBasketHandler
                                    .replace("[PRODUCT_ID]", product.id)
                                    .replace("[VARIANT_ID]", variant.id)}
                                  copyButtonText={t("common_button_copy", "Copy")}
                                  className="p-0 border-none bg-white group-hover:bg-secondary"
                                />
                              </div>
                            </div>
                          ))}
                        </Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
});

type StoreProductFormItem = { enabled: boolean; complementFor?: string | null };
type StoreProductFormData = Record<string, StoreProductFormItem>;

function StoreProductForm(props: { facilityId: string; settings: ApiObjects["StoreSettingsDto"] }) {
  const { t } = useTranslation();
  const patchSettings = useStoreSettingsPatch(props.facilityId);
  const { data: products } = useProducts();

  const form = useForm<StoreProductFormData>({
    defaultValues:
      props.settings.products.reduce(
        (obj, p) => ({
          ...obj,
          [p.productId]: { enabled: true },
        }),
        {} as StoreProductFormData
      ) || {},
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSave)}>
        <table className="space-y-2 mb-3">
          <tbody>
            {products?.map((product) => (
              <tr key={product.id}>
                <td className="p-2 text-sm">{product.name}</td>
                <td className="p-2">
                  <FormField
                    name={product.id}
                    control={form.control}
                    render={({ field }) => (
                      <div className="flex items-center space-x-3">
                        <Switch
                          checked={field.value?.enabled}
                          onCheckedChange={(enabled) => field.onChange({ ...(field.value || {}), enabled })}
                        />
                        {false && field.value?.enabled && (
                          <SelectNative
                            value={field.value?.complementFor || ""}
                            onChange={(e) => field.onChange({ ...(field.value || {}), complementFor: e.target.value })}
                          >
                            <option value="">{t("settings_store_products_complementFor_none", "Main product")}</option>
                            {products?.map((opt) => (
                              <option key={product.id + opt.id + "_opt"} value={opt.id}>
                                {t("settings_store_products_complementFor_label", "Complements {{product}}", {
                                  product: opt.name,
                                })}
                              </option>
                            ))}
                          </SelectNative>
                        )}
                      </div>
                    )}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button type="submit">{t("common_button_save")}</Button>
      </form>
    </Form>
  );

  function onSave(formData: StoreProductFormData) {
    const productsData = Object.keys(formData)
      .filter((productId) => formData[productId]?.enabled)
      .map((productId) => ({
        productId,
        complementFor: formData[productId]?.complementFor,
      }));

    patchSettings.mutate({ products: productsData });
  }
}
