import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { ApiObjects } from "@pulso/api-client";
import { formatDate } from "@pulso/utils";
import { setDayOfYear } from "date-fns";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export type FormData = {
  name: string;
  from: number;
  to: number;
};

export type SeasonFormProps = {
  seasons: ApiObjects["SeasonDto"][];
  isLoading: boolean;
  initialData: FormData;
  onSubmit: (data: FormData) => Promise<unknown>;
};

export function SeasonForm(props: SeasonFormProps) {
  const { t } = useTranslation();
  const formSchema = z
    .object({
      name: z.string().min(2).max(50),
      from: z.number(),
      to: z.number(),
    })
    .superRefine((data, ctx) => {
      const overlap = props.seasons.find((season) => season.to >= data.from && data.to >= season.from);
      if (overlap) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["from"],
          message: " ",
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["to"],
          message: t("settings_seasons_error_overlapping_seasons", "Overlapping seasons are not allowed"),
        });
      }
    });

  const firstDayOfYear = new Date(2024, 0, 1); // Has to be a leap year
  const form = createForm(
    {
      name: {
        label: t("settings_seasons_form_label_name", "Name"),
        type: "string" as const,
      },
      from: {
        label: t("settings_seasons_form_label_from", "From"),
        type: "selectNative" as const,
        options: Array.from({ length: 366 }, (_, i) => ({
          value: i + 1 + "",
          typedValue: i + 1,
          label: formatDate(setDayOfYear(firstDayOfYear, i + 1), null, undefined, "date-short-pretty"),
        })),
      },
      to: {
        label: t("settings_seasons_form_label_to", "To"),
        type: "selectNative" as const,
        options: Array.from({ length: 366 }, (_, i) => ({
          value: i + 1 + "",
          typedValue: i + 1,
          label: formatDate(setDayOfYear(firstDayOfYear, i + 1), null, undefined, "date-short-pretty"),
        })),
      },
    },
    formSchema
  );

  return (
    <PulsoFormProvider
      {...form}
      isLoading={props.isLoading}
      onSubmit={props.onSubmit}
      initialValues={props.initialData}
    />
  );
}
