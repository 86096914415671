import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { FileDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useBookingsFilter } from "../useBookingsFilter";
import { useEffect, useState } from "react";
import { Progress } from "@/components/ui/progress";
import { api } from "@/lib/api-client";
import flatten from "lodash/flatten";
import { ApiObjects } from "@pulso/api-client";
import { useGridState } from "./useGridState";
import { useColumnDefinitions } from "./useColumnDefinitions";
import { formatDate } from "@pulso/utils";
import { getSortingFromGridStateAndColDefs } from "./sortingUtil";
import { TooltipSimple } from "@/components/ui/tooltip";
import { requiredColumns } from "@/pages/reports/pages/exports/hospedajeColumns";
import jsonpath from "jsonpath";
import { useHospedajeSettings } from "@/api/useHospedajeSettings";
import { Link } from "react-router-dom";
import { Checkbox } from "@/components/ui/checkbox";

type ExportExcelButtonProps = {
  facilityId: string;
  settings: {
    enabled: boolean;
    mapping: Record<string, string>;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Comunicacion = any;

export function ExportHospedajeButton(props: ExportExcelButtonProps) {
  const { t } = useTranslation();
  const filter = useBookingsFilter();
  const [isOpen, setIsOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [bookings, setBookings] = useState<ApiObjects["BookingDto"][] | null>(null);
  const [showAllBookings, setShowAllBookings] = useState(false);
  const [onlyExportCompleted, setOnlyExportCompleted] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { currentGridState } = useGridState(props.facilityId);
  const colDefs = useColumnDefinitions(props.facilityId);
  const { settings: hospedajeSettings } = useHospedajeSettings(props.facilityId);

  useEffect(() => {
    setShowAllBookings(false);
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(isOpen) => {
          reset();
          setIsOpen(isOpen);
        }}
      >
        <DialogTrigger asChild>
          <div>
            <TooltipSimple text={"Exportar archivo para SES Hospedaje"}>
              <Button variant="outline">
                <FileDown size={16} />
              </Button>
            </TooltipSimple>
          </div>
        </DialogTrigger>
        <DialogContent hideFooter>
          <DialogHeader>
            <DialogTitle>{t("bookings_grid_export_hospedaje_title", "Export bookings")}</DialogTitle>
            <DialogDescription>
              {t(
                "bookings_grid_export_hospedaje_description",
                "The bookings you see on the screen are going to be exported in an XML file suitable for massive import in the system of SES Hospedaje."
              )}
            </DialogDescription>
          </DialogHeader>
          {!isExporting && !bookings && (
            <div>
              <div className="flex gap-1.5 items-center mb-3">
                <Checkbox
                  id="onlyExportCompleted"
                  checked={onlyExportCompleted}
                  onCheckedChange={(checked) => setOnlyExportCompleted(checked === true)}
                />
                <label htmlFor="onlyExportCompleted">
                  {t("bookings_grid_export_hospedaje_checkbox_onlyCompleted", "Only export completed bookings")}
                </label>
              </div>

              <Button onClick={onExport}>{t("bookings_grid_export_exportButton", "Export")}</Button>
            </div>
          )}
          {isExporting && <Progress value={progress} />}
          {bookings && (
            <div className="flex flex-col gap-4">
              <div className="text-sm">{t("bookings_grid_export_ready", "Your export is ready.")}</div>
              <div className="text-sm font-bold">
                {t("bookings_grid_export_bookingsCount", "{{num}} bookings will be included in the export.", {
                  num: bookings.length,
                })}
              </div>
              <div className="max-h-64 overflow-auto mb-3">
                {bookings.slice(0, showAllBookings ? 99999 : 3).map((b) => (
                  <div key={b.id} className="text-sm flex items-center gap-3 pb-1">
                    <Link
                      to={`/facility/${props.facilityId}/bookings/${b.id}`}
                      target="_blank"
                      className="text-blue-700"
                    >
                      <div className="w-20">#{b.reference}</div>
                    </Link>
                    <div>{b.customer.name}</div>
                  </div>
                ))}
                {!showAllBookings && bookings.length > 3 && (
                  <Button variant="link" className="px-0" onClick={() => setShowAllBookings(true)}>
                    {t("bookings_grid_export_showMoreButton", "+ {{num}} more", {
                      num: bookings.length - 3,
                    })}
                  </Button>
                )}
              </div>
              <div>
                <Button onClick={downloadExportedFile}>{t("common_button_download", "Download")}</Button>
              </div>
              {error && <div className="text-destructive">{error}</div>}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
  function reset() {
    setIsExporting(false);
    setProgress(0);
    setBookings(null);
  }

  async function onExport() {
    reset();
    setIsExporting(true);

    const bookings = await collectAllBookings();
    setIsExporting(false);
    setBookings(bookings);
  }

  async function collectAllBookings() {
    const pages = [];
    const pageSize = 25;
    let page = 1;
    let totalPages = 1;
    while (page <= totalPages) {
      const bookingsPage = await api.getBookings({
        facilityId: props.facilityId,
        ...filter.forApi(page),
        ...getSortingFromGridStateAndColDefs(currentGridState, colDefs),
        pageSize,
        range: currentGridState.rangeType ?? "fullBooking",
      });
      totalPages = Math.ceil(bookingsPage.total / pageSize);
      pages.push(bookingsPage.bookings);
      page++;
      setProgress(((page - 1) / totalPages) * 100);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    return flatten(pages).filter((p) =>
      onlyExportCompleted ? p.status === "COMPLETED" : p.status !== "CANCELLED" && p.status !== "ON_HOLD"
    );
  }

  async function downloadExportedFile() {
    if (!bookings) {
      return;
    }

    try {
      const communications = getCommuncationsFromBookings(bookings);
      await downloadXMLFile(communications);
    } catch (e: unknown) {
      if (typeof e === "object" && e && "message" in e && typeof e.message === "string") {
        setError(e.message);
        // console.log(e);
      }
    }
  }

  function getCommuncationsFromBookings(bookings: ApiObjects["BookingDto"][]): Comunicacion[] {
    const mapping = props.settings.mapping || {};

    const communications = bookings.map((booking) => {
      return requiredColumns.columns.reduce((acc, field) => {
        const expr = mapping[field.key];
        if (expr) {
          const value = expr.startsWith("$") ? jsonpath.query(booking, expr)[0] : expr;
          set(acc, field.key, formatValue(booking, value || "", field.format));
        }
        return acc;
      }, {} as Comunicacion);
    });

    communications.forEach((item) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      item.personas.forEach((persona: any, i: number) => {
        persona.datosPersona.rol = i === 0 ? "TI" : i === 1 ? "CP" : "CS";
      });
    });

    return communications;
  }

  function formatValue(booking: ApiObjects["BookingDto"], value: string, format?: string): string | Date | number {
    if (format && value) {
      switch (format) {
        case "dateTime":
          return (value || "").substring(0, 19);
        case "date":
          return (value || "").substring(0, 10);
        case "tipoDocumento":
          return mapValue(value, "tipoDocumento", hospedajeSettings?.valueMapping);
        case "pais":
          return mapValue(value, "pais", hospedajeSettings?.valueMapping);
      }
    }

    return value;

    function mapValue(
      value: string,
      mappingKey: string,
      mapping?: Record<string, Record<string, string>> | undefined
    ): string {
      const valueMapping = mapping?.[mappingKey];
      if (!valueMapping) {
        return value;
      }

      return valueMapping[value] || value;
    }
  }

  async function downloadXMLFile(data: Comunicacion[]) {
    const xmlContent = await getRegistroXml(data);
    const blob = new Blob([xmlContent], { type: "text/xml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const timestamp = formatDate(new Date()).replace(/[/\s:]/g, "_");
    a.download = `solicitud-${timestamp}.xml`;
    a.click();
  }

  async function getRegistroXml(comunicaciones: Comunicacion[]): Promise<string> {
    const builder = await import("xmlbuilder");

    const xml = builder
      .create("ns2:peticion", { encoding: "UTF-8" })
      .att("xmlns:ns2", "http://www.neg.hospedajes.mir.es/altaAlquilerVehiculo")
      .ele("solicitud");

    for (const data of comunicaciones) {
      const comunicacion = xml.ele("comunicacion");
      comunicacion
        .ele("contrato")
        .ele("referencia", data.contrato.referencia)
        .up()
        .ele("fechaContrato", data.contrato.fechaContrato)
        .up()
        .ele("fechaRecogida", data.contrato.fechaRecogida)
        .up()
        .ele("codigoEstablecimientoRecogida", data.contrato.codigoEstablecimientoRecogida)
        .up()
        .ele("codigoEstablecimientoDevolucion", data.contrato.codigoEstablecimientoDevolucion)
        .up()
        .ele("fechaDevolucion", data.contrato.fechaDevolucion)
        .up()
        .ele("pago")
        .ele(
          "tipoPago",
          data.contrato.pago.tipoPago === "CARD"
            ? "TARJT"
            : data.contrato.pago.tipoPago === "TRANSFER"
              ? "TRANS"
              : data.contrato.pago.tipoPago === "CASH"
                ? "EFECT"
                : data.contrato.pago.tipoPago === "PAYPAL"
                  ? "PLATF"
                  : "OTRO"
        )
        .up()
        .ele("fechaPago", data.contrato.pago.fechaPago)
        .up()
        .ele("medioPago", data.contrato.pago.medioPago)
        .up()
        .ele("titular", data.contrato.pago.titular)
        .up()
        .ele("caducidadTarjeta", data.contrato.pago.caducidadTarjeta)
        .up()
        .up()
        .up();

      comunicacion
        .ele("vehiculo")
        .ele("categoria", data.vehiculo.categoria)
        .up()
        .ele("tipo", data.vehiculo.tipo)
        .up()
        .ele("marca", data.vehiculo.marca)
        .up()
        .ele("modelo", data.vehiculo.modelo)
        .up()
        .ele("matricula", data.vehiculo.matricula)
        .up()
        .ele("numeroBastidor", data.vehiculo.numeroBastidor)
        .up()
        .ele("color", data.vehiculo.color)
        .up()
        .ele("kmRecogida", data.vehiculo.kmRecogida)
        .up()
        .ele("kmDevolucion", data.vehiculo.kmDevolucion)
        .up()
        .ele("datosGps", data.vehiculo.datosGps)
        .up()
        .up();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.values(data.personas).forEach((persona: any) => {
        const personaTag = comunicacion.ele("persona");
        personaTag
          .ele("datosPersona")
          .ele("rol", persona.datosPersona.rol)
          .up()
          .ele("nombre", persona.datosPersona.nombre)
          .up()
          .ele("apellido1", persona.datosPersona.apellido1)
          .up()
          .ele("apellido2", persona.datosPersona.apellido2)
          .up()
          .ele("tipoDocumento", persona.datosPersona.tipoDocumento)
          .up()
          .ele("numeroDocumento", persona.datosPersona.numeroDocumento)
          .up()
          .ele("fechaNacimiento", persona.datosPersona.fechaNacimiento)
          .up()
          .ele("nacionalidad", persona.datosPersona.nacionalidad)
          .up()
          .ele("sexo", persona.datosPersona.sexo)
          .up()
          .ele("direccion")
          .ele("direccion", persona.datosPersona.direccion.direccion)
          .up()
          .ele("direccionComplementaria", persona.datosPersona.direccion.direccionComplementaria)
          .up()
          .ele("codigoMunicipio", persona.datosPersona.direccion.codigoMunicipio)
          .up()
          .ele("nombreMunicipio", persona.datosPersona.direccion.nombreMunicipio)
          .up()
          .ele("codigoPostal", persona.datosPersona.direccion.codigoPostal)
          .up()
          .ele("pais", persona.datosPersona.direccion.pais)
          .up()
          .up()
          .ele("telefono", persona.datosPersona.telefono)
          .up()
          .ele("telefono2", persona.datosPersona.telefono2)
          .up()
          .ele("correo", persona.datosPersona.correo)
          .up()
          .up();
        personaTag
          .ele("permisoConducir")
          .ele("tipo", persona.permisoConducir.tipo)
          .up()
          .ele("validez", persona.permisoConducir.validez)
          .up()
          .ele("numero", persona.permisoConducir.numero)
          .up()
          .ele("soporte", persona.permisoConducir.soporte)
          .up()
          .up();
      });
    }
    return xml.end({ pretty: true });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function set(obj: any, path: string, value: any) {
    // Split the path into an array of keys
    const keys = path
      .replace(/\[([^[\]]*)\]/g, ".$1") // Convert array indices to dot notation
      .split(".")
      .filter((key) => key); // Remove any empty keys resulting from leading dots

    // Iterate through the keys to reach the target property
    keys.reduce((acc, key, index) => {
      // If we're at the last key, set the value
      if (index === keys.length - 1) {
        acc[key] = value;
      } else {
        // If the key doesn't exist or isn't an object, create an empty object
        if (!acc[key] || typeof acc[key] !== "object") {
          // Determine if the next key is a number to create an array instead of an object
          acc[key] = /^\d+$/.test(keys[index + 1]) ? [] : {};
        }
      }
      return acc[key];
    }, obj);
  }
}
